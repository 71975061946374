import React from "react"

import RichMediaMaster from "./RichMediaMaster"

type Props = {
  backgroundVideo?: string
  backgroundImage: string
  backgroundImageAlt: string
  icon?: string
  iconAlt?: string
}

const Highlight: React.FC<Props> = (props) => {
  return <RichMediaMaster type="imageWithIcon" {...props} />
}

export default Highlight
